import create from "zustand";
import moment from "moment";
import { useSessionStore } from "./Session";

export type ConfigMenuItem = {
  title?: string;
  infoDescription?: string;
  description?: string;
  botId?: string;
  icon?: string;
  actionType?: string;
  actionBody?: string;
  color?: string;
  actionOpenInNewTab?: boolean;
};

export type Config = {
  showOnlineStatus?: boolean;
  replyTime?: string;
  showNoFeatureSuggestions?: boolean;
  enableOpeningTimesBot?: boolean;
  openingTimes?: any[];
  helpcenterConfig?: any;
  timezone?: string;
  name?: string;
  color?: string;
  customCSS?: string;
  thankYouDuration?: number;
  backgroundColor?: string;
  headerColor?: string;
  buttonColor?: string;
  borderRadius?: number;
  logo?: string;
  disableBGFade?: boolean;
  disableBGGradient?: boolean;
  hideLogo?: boolean;
  buttonLogo?: string;
  hideBranding?: boolean;
  showHelpCenter?: boolean;
  hideAllConversations?: boolean;
  hideKnowledgeBase?: boolean;
  hideNews?: boolean;
  showNoHomeNews?: boolean;
  hideFeatureRequests?: boolean;
  enableConsoleLogs?: boolean;
  spamProtection?: boolean;
  enableReplays?: boolean;
  replaysInterval?: number;
  enableNetworkLogs?: boolean;
  networkLogPropsToIgnore?: string[];
  enablePrivacyPolicy?: boolean;
  enableShortcuts?: boolean;
  privacyPolicyURL?: string;
  enableMenu?: boolean;
  menuItems?: ConfigMenuItem[];
  activationMethodFeedbackButton?: boolean;
  activationMethodShake?: boolean;
  activationMethodScreenshotGesture?: boolean;
  enableCrashDetector?: boolean;
  crashDetectorIsSilent?: boolean;
  enableRageClickDetector?: boolean;
  rageClickDetectorIsSilent?: boolean;
  enableNetworkLogFilters?: boolean;
  jsMajorVersion?: string;
  enableIntercomCompatibilityMode?: boolean;
  hideWavingHandAfterName?: boolean;
  hideUsersName?: boolean;
  hideTeam?: boolean;
  widgetButtonText?: string;
  widgetInfoTitle?: string;
  widgetInfoSubtitle?: string;
  welcomeText?: string;
  showInfoPopup?: boolean;
  feedbackButtonPosition?: string;
  customTranslations?: any;
  operatorAvatarImage?: string;
  operatorName?: string;
};

interface ConfigState {
  config: Config;
  team: {
    firstName: string;
    profileImageUrl: string;
    id: string;
  }[];
  isApp: boolean;
  overrideLanguage: string;
  setOverrideLanguage: (overrideLanguage: string) => void;
  setConfig: (config: any) => void;
  getUIColor: () => string;
  isRTLLang: () => boolean;
  translateText: (key?: string) => string;
  getHeaderColor: () => string;
  getBackgroundColor: () => string;
  loadTeamInfo: () => void;
  updateMomentLanguage: () => void;
  getCurrentLanguage: (short?: boolean) => string;
  getButtonColor: () => string;
  getBorderRadius: (multiplier?: number) => number;
}

export const useConfigStore = create<ConfigState>()((set, get) => ({
  config: {},
  team: [],
  isApp: false,
  overrideLanguage: "",
  loadTeamInfo() {
    const sessionStore = useSessionStore.getState();
    set({
      team: [],
    });

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      sessionStore.apiUrl + "/config/" + sessionStore.sdkKey + "/team"
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText) {
        try {
          const data = JSON.parse(xhr.responseText);
          if (data && data.length > 0) {
            set({
              team: data,
            });
          }
        } catch (exp) { }
      }
    };

    xhr.send();
  },
  translateText: (key) => {
    if (!key) {
      return "";
    }

    const {
      getCurrentLanguage,
      config: { customTranslations },
    } = get();
    const language = getCurrentLanguage();

    if (typeof customTranslations === "undefined") {
      return key;
    }

    const searchForTranslationTable = (langKey: string) => {
      var customTranslation = null;
      const translationKeys = Object.keys(customTranslations);
      for (var i = 0; i < translationKeys.length; i++) {
        const translationKey = translationKeys[i];
        if (
          langKey &&
          translationKey &&
          langKey === translationKey.toLowerCase()
        ) {
          if (customTranslations[translationKey]) {
            customTranslation = customTranslations[translationKey];
          }
        }
      }

      return customTranslation;
    };

    var customTranslation = searchForTranslationTable(language);

    // Extended search for language match only.
    if (!customTranslation && language) {
      const langKeys = language.split("-");
      if (langKeys && langKeys.length > 1) {
        customTranslation = searchForTranslationTable(langKeys[0]);
      }
    }

    if (customTranslation && customTranslation[key]) {
      return customTranslation[key];
    }

    return key;
  },
  isRTLLang: () => {
    return get().translateText("rtlLang") === "true";
  },
  getCurrentLanguage: (short = false) => {
    const overrideLanguage = get().overrideLanguage;
    var language = "en";
    if (typeof navigator.language !== "undefined") {
      language = navigator.language.substring(0, 2).toLowerCase();
    }
    if (overrideLanguage && overrideLanguage !== "") {
      language = overrideLanguage.toLowerCase();
    }
    if (short) {
      return language.substring(0, 2);
    }
    return language;
  },
  setConfig: (config: any) => {
    set({
      config,
    });
    get().updateMomentLanguage();
  },
  setOverrideLanguage: (overrideLanguage: string) => {
    set({ overrideLanguage });
    get().updateMomentLanguage();
  },
  updateMomentLanguage: () => {
    moment.locale(get().getCurrentLanguage(true));
  },
  getUIColor: () => {
    return get().config.color || "#000000";
  },
  getHeaderColor: () => {
    return get().config.headerColor || "#000000";
  },
  getBackgroundColor: () => {
    return get().config.backgroundColor || "#ffffff";
  },
  getButtonColor: () => {
    return get().config.buttonColor || "#000000";
  },
  getBorderRadius: (multiplier = 1) => {
    const staticFactor = 0.8;
    return Math.round(
      (get().config.borderRadius || 20) * multiplier * staticFactor
    );
  },
}));
