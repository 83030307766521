import create from "zustand";
import Communicator from "../Helper/Communicator";
import { useFormStore } from "./Form";

export enum AppMode {
  WIDGET = "widget",
  SURVEY_FULL = "survey_full",
  SURVEY = "survey",
}

export enum NavigationDirection {
  FORWARD = "forward",
  BACKWARD = "backward",
}

interface RouterState {
  appMode: AppMode;
  navigationDirection: NavigationDirection;
  currentPage: string;
  firstAnimation: boolean;
  currentData: any;
  hideBackButton: boolean;
  setPage: (
    page: string,
    data?: any,
    appMode?: AppMode,
    hideBackButton?: boolean
  ) => void;
  setHideBackButton: (hideBackButton: boolean) => void;
}

export const useRouterStore = create<RouterState>()((set, get) => ({
  appMode: AppMode.WIDGET,
  currentPage: "menu",
  currentData: {},
  firstAnimation: true,
  hideBackButton: false,
  navigationDirection: NavigationDirection.FORWARD,
  setHideBackButton: (hideBackButton: boolean) => {
    set({
      hideBackButton: hideBackButton,
    });
  },
  setPage: (page: string, data: any, appMode: AppMode = AppMode.WIDGET) => {
    const navigationDirection =
      get().currentPage === "menu"
        ? NavigationDirection.FORWARD
        : NavigationDirection.BACKWARD;

    Communicator.pageChanged(page);

    var newState: any = {
      currentPage: page,
      currentData: data,
      navigationDirection: navigationDirection,
      appMode,
    };

    if (page !== "menu") {
      newState.firstAnimation = false;
    }

    if (page === "flow" && data.flowId) {
      // Set feedbackFlow in store.
      useFormStore.setState({
        feedbackFlow: data.flowId,
      });
    }

    set(newState);
  },
}));
