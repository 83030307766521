import {
  WebSocketEvent,
  WebSocketEventData,
} from "../Models/WebSocketEventData";
import { useTicketStore } from "../Stores/Ticket";

export const handleWebSocketEvent = (
  event: string,
  data: WebSocketEventData
) => {
  // Send event to target
  switch (event) {
    case WebSocketEvent.BUG_TYPING:
    case WebSocketEvent.BUG_UPDATED:
    case WebSocketEvent.BUG_CREATED:
    case WebSocketEvent.MESSAGE_CREATED:
    case WebSocketEvent.BOT_OPENLINK:
    case WebSocketEvent.BOT_STARTFEEDBACKFLOW:
    case WebSocketEvent.BOT_UPDATECURRENTACTION:
      useTicketStore.getState().handleWebSocketEvent(data);
      break;

    default:
      break;
  }
};
