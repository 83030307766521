import { useConfigStore } from '../../Stores/Config';
import { useFormStore } from '../../Stores/Form';
import "./NPSFormItem.scss";

function NPSFormItem({ formItem }: { formItem: any }) {
    const configStore = useConfigStore();
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);
    
    const npsType = formItem.npsType ? formItem.npsType : 'classic';
    var fields: any[] = [];
    if (npsType === 'classic') {
        fields = Array.from({ length: 11 }, (_, i) => {
            return {
                value: i,
                label: i
            };
        });
    } else if (npsType === 'modern') {
        fields = Array.from({ length: 6 }, (_, i) => {
            return {
                value: i * 2,
                label: i
            };
        });
    } else if (npsType === 'emoji') {
        fields = [{
            value: 0,
            label: '😡'
        }, {
            value: 2,
            label: '😢'
        }, {
            value: 6,
            label: '😐'
        }, {
            value: 8,
            label: '😊'
        }, {
            value: 10,
            label: '😍'
        }];
    }

    const renderOption = (value: any) => {
        return (<div className={`nps-form-item-item ${(fieldFormData && fieldFormData.value === value.value) && "nps-form-item-item--active"}`} key={value.value} onClick={(() => {
            setFormData(formItem.name, {
                value: value.value,
                dirty: true
            }, true);
        })}>
            <span>{value.label}</span>
        </div>);
    };

    return (
        <div className={`nps-form-item nps-form-item-${npsType}`}>
            <div className="nps-form-item-items">
                {fields.map((value: any) => renderOption(value))}
            </div>
            {formItem.hideInfoLabel ? null : <div className="nps-form-item-labels">
                <span>{fields[0].label} - {configStore.translateText(formItem.lowestValueLabel)}</span>
                <span>{fields[fields.length - 1].label} - {configStore.translateText(formItem.highestValueLabel)}</span>
            </div>}
        </div>
    );
}

export default NPSFormItem;
