import create from "zustand";
import { useTicketStore } from "./Ticket";

export type Session = {
  gleapId?: string;
  gleapHash?: string;
  name?: string;
  email?: string;
  userId?: string;
};

interface SessionState {
  session?: Session | null;
  sdkKey: string;
  apiUrl: string;
  setSession: (session: Session) => void;
  getName: () => string | undefined;
}

export const useSessionStore = create<SessionState>()((set, get) => ({
  session: null,
  sdkKey: "",
  apiUrl: "https://api.gleap.io",
  setSession: (session: Session) => {
    var sessionDidUpdate = false;
    if (get().session?.gleapId !== session.gleapId) {
      sessionDidUpdate = true;
    }

    set({
      session,
    });

    if (sessionDidUpdate) {
      useTicketStore.getState().loadConversations();
    }
  },
  getName: () => {
    const { session } = get();

    if (!session) {
      return undefined;
    }

    try {
      return session.name ? session.name.split(' ')[0].split('@')[0].split('.')[0].split('+')[0] : "";
    } catch (exp) {
      return session.name;
    }
  }
}));
