import { ConfigMenuItem, useConfigStore } from '../../Stores/Config';
import { useNewsStore } from '../../Stores/News';
import { useTicketStore } from '../../Stores/Ticket';
import ConversationItem from '../ConversationItem/ConversationItem';
import NewsCard from '../NewsCard/NewsCard';
import WidgetMenuButton from '../WidgetMenuButton/WidgetMenuButton';
import "./WidgetMenu.scss";

function WidgetMenu() {
    const configStore = useConfigStore();
    const ticketStore = useTicketStore();
    const newsStore = useNewsStore();

    const unreadTickets = ticketStore.previousTickets.filter((ticket) => {
        if (ticket.sessionNotificationsUnread) {
            return true;
        }
        return false;
    });

    const renderNews = () => {
        if (configStore.config.hideNews || !newsStore.latestArticle || configStore.config.showNoHomeNews) {
            return null;
        }

        return (
            <div className='widget-app-container'>
                <div className='widget-app-container-body'>
                    <NewsCard news={newsStore.latestArticle} />
                </div>
            </div>
        );
    }

    return (<>
        {unreadTickets && unreadTickets.length > 0 &&
            <div className='widget-unread-messages-container'>
                {
                    unreadTickets.slice(0, 2).map((ticket, index) => {
                        return <ConversationItem ticket={ticket} index={index} key={ticket.bugId} />
                    })
                }
            </div>}
        {configStore.config?.menuItems?.map((menuItem: ConfigMenuItem, key: any) => {
            return (
                <div className='widget-menu-button-container' key={key}>
                    <div className='widget-menu-buttons'>
                        <WidgetMenuButton menuItem={menuItem} index={key} />
                    </div>
                </div>);
        })}
        {renderNews()}
    </>);
}

export default WidgetMenu;
