export const loadFromGleapCache = (key: string) => {
    try {
        const cachedData = localStorage.getItem(key);
        if (cachedData) {
            const config = JSON.parse(cachedData);
            return config;
        }
    } catch (exp) { }
    return null;
};

export const saveToGleapCache = (key: string, data: any) => {
    if (data) {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (exp) { }
    } else {
        localStorage.removeItem(key);
    }
};
