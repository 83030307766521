import "./SurveyIntro.scss";
import { AppMode, useRouterStore } from '../../Stores/Router';
import { useSessionStore } from "../../Stores/Session";
import { useConfigStore } from "../../Stores/Config";
import { useFormStore } from "../../Stores/Form";
import { contentWithVariables } from "../../Helper/ContentReplace";

function SurveyIntro({ currentAction }: {
    currentAction: any;
}) {
    const routerStore = useRouterStore();
    const configStore = useConfigStore();
    const formStore = useFormStore();
    const session = useSessionStore((state) => state.session);
    const name = session?.name ? session?.name.split(' ')[0] : "";

    if (!(routerStore.appMode === AppMode.SURVEY_FULL && currentAction && (currentAction.introTitle || currentAction.introMessage))) {
        return null;
    }

    if (formStore.currentStep !== 0) {
        return null;
    }

    const htmlForContent = (content: string) => {
        if (!content) {
            return "";
        }

        return contentWithVariables(content, {
            name,
        });
    }

    return (<div className="survey-intro">
        {currentAction.introTitle && <h1>{htmlForContent(configStore.translateText(currentAction.introTitle))}</h1>}
        {currentAction.introMessage && <p>{htmlForContent(configStore.translateText(currentAction.introMessage))}</p>}
    </div>);
}

export default SurveyIntro;
