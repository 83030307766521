import "core-js/es/object/values";
import 'core-js/es/array/flat';
import 'core-js/es/object/entries';
import 'core-js/es/object/from-entries';
import ReactDOM from "react-dom/client";
import App from "./Views/App/App";
import HeightUpdateManager from "./Helper/HeightUpdateManager";
import CommunicationManager from "./Helper/CommunicationManager";
import "./index.scss";
import 'moment/min/locales';
import Communicator from "./Helper/Communicator";

var communicationInitialized = false;
const initializeCommunication = () => {
  if (communicationInitialized) {
    return;
  }
  communicationInitialized = true;
  HeightUpdateManager.getInstance().start();
  CommunicationManager.getInstance().sendMessage({
    name: "ping"
  });
}
initializeCommunication();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <App />
);

document.onclick = function (e) {
  e = e || window.event;
  var element: any = e.target || e.srcElement;
  if (element && element.tagName === 'A' && element.getAttribute("target") === "_blank" && element.href && element.href.length > 0) {
    Communicator.openURL(element.href);
    return false;
  }
};