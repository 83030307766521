import { useConfigStore } from '../Stores/Config';

export const validateRecaptchaAction = (action: string) => {
    const config = useConfigStore.getState().config;
    if (config && config.spamProtection) {
        return new Promise((resolve, reject) => {
            const grecaptcha = (window as any).grecaptcha;
            if (grecaptcha) {
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LeMpiwcAAAAAAuGag4PWJvwSSgH0mCVX7EDQIjT', { action }).then(function (token: string) {
                        resolve(token);
                    }).catch(() => {
                        reject();
                    });
                });
            } else {
                reject();
            }
        });
    } else {
        return Promise.resolve(undefined);
    }
};