import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as SendLogo } from '../../Assets/paper-plane-top-solid.svg';
import AnswerBotChatMessageComposer from "../../Components/AnswerBotChatMessageComposer/AnswerBotChatMessageComposer";
import BotActionInput, { actionIsLastOfGroup } from "../../Components/BotActionInput/BotActionInput";
import ChatMessage from "../../Components/ChatMessage/ChatMessage";
import ChatMessageComposer from "../../Components/ChatMessageComposer/ChatMessageComposer";
import Communicator from "../../Helper/Communicator";
import { useConfigStore } from "../../Stores/Config";
import { useRouterStore } from "../../Stores/Router";
import { useSessionStore } from "../../Stores/Session";
import { useTicketStore } from "../../Stores/Ticket";
import "./Conversation.scss";

function Conversation() {
    const routerStore = useRouterStore();
    const feedbackItemStore = useTicketStore();
    const sessionStore = useSessionStore();
    const ticketStore = useTicketStore();
    const configStore = useConfigStore();
    const [composerHeight, setComposerHeight] = useState(57);
    const bottomRef = useRef(null as any);
    const isBot = feedbackItemStore.currentTicket?.type === "BOT";
    var isAnswerBotInput = isBot && feedbackItemStore.currentBotAction?.action?.type === "answerbotflow";
    const canShowComposer = feedbackItemStore.currentTicket && !isBot && !feedbackItemStore.loadingComments;
    const showTypingIndicator = ticketStore.typingUser || (!feedbackItemStore.currentTicket || feedbackItemStore.loadingComments) || (isBot && !feedbackItemStore.currentBotAction && !feedbackItemStore.currentTicket.conversationClosed);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const articleDivs = document.querySelectorAll(
            ".helpcenter-conversation-article"
        );

        const handleClick = (event: any) => {
            const articleUrl = event.currentTarget.getAttribute("data-url");
            if (articleUrl) {
                Communicator.openURL(articleUrl, true);
            }
        };

        articleDivs.forEach((articleDiv) => {
            articleDiv.addEventListener("click", handleClick);
        });

        return () => {
            articleDivs.forEach((articleDiv) => {
                articleDiv.removeEventListener("click", handleClick);
            });
        };
    }, [feedbackItemStore.currentComments]);

    useEffect(() => {
        if (routerStore.currentData && routerStore.currentData.shareToken) {
            feedbackItemStore.loadCurrentTicket(routerStore.currentData.shareToken);
        }
    }, [routerStore.currentData, ticketStore.previousTickets]);

    useEffect(() => {
        return () => {
            feedbackItemStore.clearCurrentTicket();
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [feedbackItemStore.currentComments, feedbackItemStore.currentBotAction, ticketStore.currentTicket?.conversationClosed, ticketStore.currentTicket?.type]);

    const renderMessageComposer = () => {
        if (!canShowComposer || isAnswerBotInput) {
            return null;
        }

        return (<ChatMessageComposer typingStatusChanged={(isTyping) => {
            ticketStore.isTyping(isTyping);
        }} onHeightUpdated={(height) => {
            setComposerHeight(height);
        }} sendMessage={(message, attachments) => {
            feedbackItemStore.sendComment(message, attachments);
        }} />);
    }

    const renderAnswerBotMessageComposer = () => {
        if (!isAnswerBotInput || canShowComposer) {
            return null;
        }

        return (<AnswerBotChatMessageComposer onHeightUpdated={(height) => {
            setComposerHeight(height);
        }} sendMessage={(message) => {
            ticketStore.answerBotAction({
                question: message,
                message: message,
                isFirstMessage: !feedbackItemStore.hasAnswerBotReply(),
            });
        }} />);
    }

    const renderConversationClosed = () => {
        if (!ticketStore.currentTicket?.conversationClosed) {
            return null;
        }

        return (
            <div className="conversation-ended">
                <div className="conversation-ended-label">{configStore.translateText("Your conversation has ended.")}</div>
                <div className="action-button form-send-button" onClick={() => {
                    ticketStore.createNewConversation({});
                }}>
                    {configStore.translateText("Send us a message")}
                    <SendLogo />
                </div>
            </div>
        );
    }

    var bottomToolbarHeight = canShowComposer ? composerHeight : 0;
    if (ticketStore.currentTicket?.conversationClosed) {
        bottomToolbarHeight = 140;
    }
    if (isAnswerBotInput) {
        bottomToolbarHeight = composerHeight;
    }

    return (
        <div className="conversation">
            <div className="conversation-messages" style={{
                height: `calc(100% - ${bottomToolbarHeight}px)`
            }}>
                {!feedbackItemStore?.currentTicket?.hideContent &&
                    <ChatMessage key="initial" chatMessage={{
                        type: "FEEDBACK_ITEM",
                        data: feedbackItemStore.currentTicket,
                        createdAt: feedbackItemStore.currentTicket?.createdAt
                    }} isCreator={true} showTime={true} lastOfGroup={true} />
                }
                {feedbackItemStore.currentComments && feedbackItemStore.currentComments.map((comment, i, currentComments) => {
                    var nextComment = null;
                    var isLastInSection = false;
                    var showTime = true;
                    if (i < currentComments.length) {
                        nextComment = currentComments[i + 1];
                    }

                    if (!nextComment || !((comment.user && nextComment.user === comment.user) || (comment.session && nextComment.session === comment.session))) {
                        isLastInSection = true;
                    }

                    if (comment.type === "BOT" && nextComment?.type === "BOT_INPUT_REPLY") {
                        isLastInSection = false;
                    }

                    if (comment.type === "BOT_INPUT_REPLY" && nextComment?.type === "BOT_INPUT_REPLY") {
                        isLastInSection = false;
                    }

                    if (nextComment && !isLastInSection) {
                        var duration = moment.duration(moment(nextComment.createdAt).diff(comment.createdAt));
                        var minutes = duration.asMinutes();
                        if (minutes < 30) {
                            showTime = false;
                        }
                    }

                    var isCreator = false;
                    if (comment.session?.gleapId === sessionStore?.session?.gleapId) {
                        isCreator = true;
                    }

                    if (isLastInSection && !nextComment && isBot && actionIsLastOfGroup(feedbackItemStore.currentBotAction)) {
                        isLastInSection = false;
                        showTime = false;
                    }

                    // Check if last comment and active bot action.
                    if ((i === feedbackItemStore.currentComments?.length - 1) && ['answerbotflow'].includes(feedbackItemStore?.currentBotAction?.action?.type)) {
                        isLastInSection = false;
                        showTime = false;
                    }

                    return <ChatMessage key={comment.id} chatMessage={comment} showTime={showTime} isCreator={isCreator} lastOfGroup={isLastInSection} />
                })}
                {isBot && <BotActionInput key="bot-action-input" />}
                {showTypingIndicator && (
                    <ChatMessage key="typing" lastOfGroup={true} chatMessage={{
                        data: {
                            content: "...",
                        },
                        user: ticketStore.typingUser,
                        type: "TEXT"
                    }}
                        forceTyping={true}
                        showTime={false}
                    />
                )}
                <div ref={bottomRef} />
            </div>
            {renderAnswerBotMessageComposer()}
            {renderMessageComposer()}
            {renderConversationClosed()}
        </div>
    );
}

export default Conversation;
