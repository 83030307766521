import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as SendLogo } from "../../Assets/paper-plane-top-solid.svg";
import { ReactComponent as AttachmentButton } from "../../Assets/paperclip-solid.svg";
import { debounce } from "lodash";
import { useConfigStore } from "../../Stores/Config";
import "./ChatMessageComposer.scss";

export const defaultAttachmentMessage = "📎 Attachment";

function ChatMessageComposer({
  sendMessage,
  onHeightUpdated,
  typingStatusChanged,
}: {
  sendMessage: (message: string, attachments: File[]) => void;
  onHeightUpdated: (height: number) => void;
  typingStatusChanged: (isTyping: boolean) => void;
}) {
  const config = useConfigStore();
  const textareaRef = useRef(null as any);
  const [currentValue, setCurrentValue] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([] as any);

  const debouncedUserTypingPing = useCallback(
    debounce((text: any) => {
      var isTyping = false;

      if (text && text.length > 0) {
        isTyping = true;
      }

      typingStatusChanged(isTyping);
    }, 800, {
      'leading': true,
      'trailing': false
    }),
    [],
  );

  useEffect(() => {
    if (selectedFiles && selectedFiles.length > 0) {
      internalSendMessage();
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      var scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight < 57) {
        scrollHeight = 57;
      }

      textareaRef.current.style.height = scrollHeight + "px";
      onHeightUpdated(scrollHeight);
    }
  }, [currentValue]);

  const internalSendMessage = () => {
    if (!((currentValue && currentValue.length > 0) || selectedFiles.length > 0)) {
      return;
    }

    sendMessage((currentValue && currentValue.length > 0) ? currentValue : defaultAttachmentMessage, selectedFiles);
    setCurrentValue("");
    setSelectedFiles([]);
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      internalSendMessage();
    }
  };

  const renderAttachment = () => {
    return (
      <AttachmentButton
        className={`attachment-button ${selectedFiles &&
          selectedFiles.length > 0 &&
          "attachment-button--fileselected"
          }`}
        onClick={() => {
          var inputElement = document.createElement('input');
          inputElement.style.display = 'none';
          if (document.body) {
            document.body.appendChild(inputElement);
          }
          inputElement.type = 'file';
          inputElement.accept =
            ".json,.pdf,.txt,.xlsx,image/*,.mp4,.mp3,.mov,.webp,.webm";
          inputElement.multiple = false;
          inputElement.addEventListener('change', arg => {
            if (inputElement.files && inputElement.files.length > 0) {
              setSelectedFiles(Array.from(inputElement.files));
            }

            document.body.removeChild(inputElement);
          });
          inputElement.dispatchEvent(new MouseEvent('click'));
        }}
      />
    );
  };

  return (
    <div className="chat-message-composer">
      <textarea
        value={currentValue}
        placeholder={config.translateText("Write a reply...")}
        ref={textareaRef}
        onChange={(e) => {
          setCurrentValue(e.target.value);
          debouncedUserTypingPing(e.target.value);
        }}
        onKeyDown={onEnterPress}
      />
      <div className="composer-buttons">
        {renderAttachment()}
        <SendLogo
          onClick={() => {
            internalSendMessage();
          }}
          className={`send-button ${!(currentValue && currentValue.length > 0) &&
            `send-button--disabled`
            } `}
        />
      </div>
    </div>
  );
}

export default ChatMessageComposer;
