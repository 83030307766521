import './FormLoading.css';

function FormLoading() {
    return (
        <div className="gleap-loading">
            {[0, 1, 2, 3].map((_, i) => <div key={i} />)}
        </div>
    );
}

export default FormLoading;
