import create from "zustand";
import { useAppViewStore } from "./AppView";
import { useConfigStore } from "./Config";
import { useSessionStore } from "./Session";

interface FeatureRequestState {
  open: () => void;
  openFeatureRequest: (shareToken?: string) => void;
}

export const useFeatureRequestStore = create<FeatureRequestState>()((set, get) => ({
  open: () => {
    get().openFeatureRequest();
  },
  openFeatureRequest: (shareToken?: string) => {
    const sessionStore = useSessionStore.getState();
    var details = "";
    if (shareToken && shareToken.length > 0) {
      details = `/featurerequests/${shareToken}`;
    }
    useAppViewStore.getState().openApp(`https://app.gleap.io/sharedboard/${sessionStore.sdkKey}${details}?widgetApp=widget&gleapId=${sessionStore.session?.gleapId}&gleapHash=${sessionStore.session?.gleapHash}`, "Roadmap", useConfigStore.getState().translateText("Roadmap"));
  },
}));
