import FormLoading from "../../Components/FormLoading/FormLoading";
import "./ConversationLoading.scss";

function ConversationLoading() {
    return (
        <div className="conversation">
            <div className="conversation-messages" style={{
                height: "100%"
            }}>
                <div className="conversation-messages-loading">
                    <FormLoading />
                </div>
            </div>
        </div>
    );
}

export default ConversationLoading;
