import { ReactComponent as ArrowRight } from '../../Assets/angle-right-solid.svg';
import moment from "moment";
import { Article } from "../../Stores/News";
import { useRouterStore } from "../../Stores/Router";
import "./NewsCard.scss";

function NewsCard({ news }: { news: Article }) {
  const routerStore = useRouterStore();

  if (!news) {
    return null;
  }

  return (
    <div
      className="news-card"
      onClick={() => {
        routerStore.setPage("newsdetails", { id: news.id });
      }}
    >
      <img className="news-card-image" src={news.coverImageUrl} />
      <div className="news-card-content">
        <div className="news-card-content-content">
          <div className="news-card-content-title">{news.subject}</div>
          <div className="news-card-content-meta">
            {news.sender && (
              <>
                <img src={news.sender.profileImageUrl} />
                <span>
                  {news.sender.firstName} •{" "}
                  {news.sentAt
                    ? moment(news.sentAt).fromNow()
                    : moment(news.createdAt).fromNow()}
                </span>
              </>
            )}
          </div>
        </div>
        <ArrowRight className='news-card-content-show-details' />
      </div>
    </div>
  );
}

export default NewsCard;
