import create from "zustand";
import { useAppViewStore } from "./AppView";
import { useConfigStore } from "./Config";
import { useSessionStore } from "./Session";

interface HelpCenterState {
  open: () => void;
  searchHelpCenter: (searchTerm: string) => void;
  openArticle: (articleId: string) => void;
  openCollection: (collectionId: string) => void;
  openHelpCenter: (data?: {
    collectionId?: string;
    articleId?: string;
    searchTerm?: string;
  }) => void;
}

const checkIfLangIsAvailableInHelpCenterConfig = (lang: string) => {
  const flowConfig = useConfigStore.getState().config;
  const possibleLangs = flowConfig?.helpcenterConfig?.localization ?? [];
  for (let i = 0; i < possibleLangs.length; i++) {
    if (possibleLangs[i].language === lang) {
      return true;
    }
  }

  return false;
};

const getDefaultHelpCenterLanguage = () => {
  if (checkIfLangIsAvailableInHelpCenterConfig("en")) {
    return "en";
  }

  const flowConfig = useConfigStore.getState().config;
  const possibleLangs = flowConfig?.helpcenterConfig?.localization ?? [];
  if (possibleLangs.length > 0) {
    return possibleLangs[0].language;
  }

  return "en";
};

export const useHelpCenterStore = create<HelpCenterState>()((set, get) => ({
  open: () => {
    get().openHelpCenter();
  },
  searchHelpCenter: (searchTerm: string) => {
    get().openHelpCenter({
      searchTerm,
    });
  },
  openArticle: (articleId: string) => {
    get().openHelpCenter({
      articleId,
    });
  },
  openCollection: (collectionId: string) => {
    get().openHelpCenter({
      collectionId,
    });
  },
  openHelpCenter: (data?: {
    collectionId?: string;
    articleId?: string;
    searchTerm?: string;
  }) => {
    const sessionStore = useSessionStore.getState();
    const { collectionId, articleId, searchTerm } = data || {};

    var details = "";
    if (collectionId) {
      details = `/collections/${collectionId}-collection`;
    }

    if (articleId) {
      details = `/articles/${articleId}-article`;
    }

    if (searchTerm) {
      details = `/search?q=${encodeURIComponent(searchTerm ?? "")}`;
    }

    var lang = useConfigStore.getState().getCurrentLanguage(true);
    if (!checkIfLangIsAvailableInHelpCenterConfig(lang)) {
      lang = getDefaultHelpCenterLanguage();
    }

    useAppViewStore
      .getState()
      .openApp(
        `https://${sessionStore.sdkKey}-app.gleap.help/${lang}${details}`,
        "Help",
        undefined
      );
  },
}));
