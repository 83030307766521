import NewsCard from "../../Components/NewsCard/NewsCard";
import { useConfigStore } from "../../Stores/Config";
import { useNewsStore } from "../../Stores/News";
import "./News.scss";

function News() {
    const newsStore = useNewsStore();
    const articles = useNewsStore(state => state.articles);
    const configStore = useConfigStore();

    return (
        <div className="news">
            {newsStore.articles.length === 0 && !newsStore.loadingNews && (
                <div className="no-news">
                    <span>{configStore.translateText("No news published yet.")}</span>
                </div>
            )}
            {articles.map((news) => {
                return <NewsCard news={news} key={news.id} />
            })}
            {(newsStore.articles.length !== 0 && newsStore.currentOffset % 10 === 0) &&
                <div className={`load-more`} onClick={() => {
                    newsStore.loadMoreNews();
                }}>
                    <span>{configStore.translateText("Load more")}</span>
                </div>}
        </div>
    );
}

export default News;
