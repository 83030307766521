import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import HelpCenterArticle from "../Components/HelpCenterArticle/HelpCenterArticle";
import * as ReactDOMServer from "react-dom/server";
import React from "react";

export const HelpCenterArticleExtension = Node.create({
  name: "helpCenterArticle",
  group: "block",
  atom: true,

  addNodeView() {
    return ReactNodeViewRenderer(HelpCenterArticle);
  },

  renderHTML({ HTMLAttributes }) {
    const article = ReactDOMServer.renderToString(
      React.createElement(HelpCenterArticle, {
        node: { attrs: HTMLAttributes },
      })
    );

    return ["helpcenterarticle", HTMLAttributes, article];
  },

  addAttributes() {
    return {
      articleId: {
        default: null,
      },
      articleTitle: {
        default: "No title",
      },
      articleDescription: {
        default: "No description",
      },
      articleUrl: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "helpcenterarticle",
        getAttrs: (node) => {
          if (typeof node === "string") {
            return {};
          }

          if (node.nodeType !== 1 /* Node.ELEMENT_NODE */) {
            return {};
          }

          const element = node as HTMLElement;
          return {
            articleId: element.getAttribute("articleId") || null,
            articleTitle: element.getAttribute("articleTitle") || "No title",
            articleDescription:
              element.getAttribute("articleDescription") || "No description",
            articleUrl: element.getAttribute("articleUrl") || null,
          };
        },
      },
    ];
  },

  renderText() {
    return "";
  },
});
