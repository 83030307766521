import React from 'react';
import { useFormStore } from '../../Stores/Form';
import { AppMode, useRouterStore } from '../../Stores/Router';
import "./FormProgressBar.scss";

function FormProgressBar() {
    const formStore = useFormStore();
    const routerStore = useRouterStore();
    const currentAction = formStore.action;
    var showProgressBar = currentAction.showProgressBar ?? true;
    if (formStore.isSurvey()) {
        showProgressBar = currentAction.showProgressBar ?? false;
    }

    if (!showProgressBar) {
        return null;
    }

    if (formStore.hasOnlyOnePage()) {
        return null;
    }

    if (routerStore.appMode === AppMode.SURVEY_FULL) {
        return null;
    }

    const progress = formStore.getProgress();
    if (progress === 0) {
        return null;
    }

    return (
        <div className='progress-bar-container'>
            <div className='progress-bar-inner' style={{
                width: `${formStore.getProgress()}%`
            }}></div>
        </div>
    );
}

export default FormProgressBar;
