import Color from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Youtube from "@tiptap/extension-youtube";
import { generateHTML } from "@tiptap/html";
import StarterKit from "@tiptap/starter-kit";
import moment from "moment";
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import { contentWithVariables } from "../../Helper/ContentReplace";
import CustomLink from "../../Helper/CustomLink";
import {
  getDataDescription,
  stripHtml,
  truncate,
} from "../../Helper/FeedbackItemHelper";
import { HelpCenterArticleExtension } from "../../Helper/HelpcenterExtension";
import IFrame from "../../Helper/IFrame";
import { replaceAll } from "../../Helper/String";
import { useConfigStore } from "../../Stores/Config";
import { useRouterStore } from "../../Stores/Router";
import { useSessionStore } from "../../Stores/Session";
import { Ticket } from "../../Stores/Ticket";
import { getOperatorInfo } from "../WidgetHeader/WidgetHeader";
import "./ConversationItem.scss";

function ConversationItem({
  ticket,
  index,
}: {
  ticket: Ticket;
  index: number;
}) {
  const routerStore = useRouterStore();
  const config = useConfigStore();

  const getTextFromLatestComment = () => {
    var text = null;
    if (ticket.latestComment?.data && ticket.latestComment?.data.text) {
      text = ticket.latestComment?.data.text;
    }

    var contentData = ticket.latestComment?.data?.content;

    if (
      contentData &&
      (typeof contentData === "string" || contentData instanceof String)
    ) {
      text = contentData;
    }

    if (contentData) {
      try {
        var html = generateHTML(contentData, [
          StarterKit.configure({ codeBlock: {}, heading: false }),
          Heading.configure({
            levels: [1, 2, 3],
          }),
          Mention,
          Placeholder,
          CustomLink,
          Image,
          TextStyle,
          Youtube.configure({ controls: true }),
          IFrame,
          HelpCenterArticleExtension,
          Color.configure({
            types: ["textStyle"],
          }),
        ]);

        let regex = /<helpcenterarticle.*?>.*?<\/helpcenterarticle>/gs;
        let matches = Array.from(html.matchAll(regex));

        for (let i = 0; i < matches.length; i++) {
          let match: any = matches[i];
          let textToReplace = match[0];

          // perform the necessary replacements on textToReplace
          let replacedText = replaceAll(textToReplace, "&lt;", "<");
          replacedText = replaceAll(replacedText, "&gt;", ">");
          replacedText = replaceAll(replacedText, "&quot;", '"');
          replacedText = replaceAll(replacedText, "helpcenterarticle", "div");
          // replace the original text with the modified text
          html = html.replace(textToReplace, replacedText);
        }

        html = replaceAll(html, "\n", "<br>");

        text = stripHtml(html);
      } catch (exp) { }
    }

    if (ticket.form?.description?.value) {
      text = ticket.form?.description?.value;
    }

    if (text) {
      return contentWithVariables(text, {
        name: useSessionStore.getState().getName(),
      });
    }

    return null;
  };

  const getDescription = () => {
    var description = getDataDescription({ data: ticket, maxLength: 100 });

    if (ticket.latestComment) {
      const textFromLatestComment = getTextFromLatestComment();
      if (textFromLatestComment) {
        description = textFromLatestComment;
      }
    }

    if (description === "No content") {
      description = config.translateText("No messages yet");
    }

    return truncate(description, 58);
  };

  const getLastUpdateDate = () => {
    if (ticket.latestComment) {
      return moment(ticket.latestComment.createdAt);
    }

    return moment(ticket.createdAt);
  };

  const operatorInfo = getOperatorInfo(config, config.config, ticket);

  const renderAvatarImage = () => {
    if (operatorInfo?.image) {
      return (
        <div className="operator-info-image">{operatorInfo?.image}</div>
      );
    }

    if (config.team && config.team.length > 0) {
      const team = config.team.slice(0, 3);
      if (team.length > 2) {
        return (
          <div className="operator-info-team-image">{team.map((teamUser, index) => {
            return (<img key={index} src={teamUser.profileImageUrl} />)
          })}</div>
        );
      } else {
        return (<div className="operator-info-image"><img src={team[0].profileImageUrl} /></div>);
      }
    }

    return null;
  }

  return (
    <div
      className={`conversation-item ${ticket.sessionNotificationsUnread && "conversation-item--unread"
        }`}
      onClick={() => {
        routerStore.setPage("conversation", {
          shareToken: ticket.shareToken,
        });
      }}
    >
      <div className="conversation-item--inner">
        <div className="conversation-item-avatar">{renderAvatarImage()}</div>
        <div className="conversation-item-labels">
          <div className="conversation-item-title">{getDescription()}</div>
          <div className="conversation-item-description">
            {operatorInfo?.name && `${operatorInfo.name} • `}
            {getLastUpdateDate().fromNow()}
          </div>
        </div>
        {ticket.sessionNotificationsUnread ? <div className="conversation-item-unreaddot" /> : <ArrowRight className="conversation-item-indicator" />}
      </div>
    </div>
  );
}

export default ConversationItem;
