import CommunicationManager from "./CommunicationManager";

export default class Communicator {
    static openURL(url: string, newTab: boolean = false) {
        CommunicationManager.getInstance().sendMessage({
            name: "open-url",
            data: url,
            newTab: newTab
        });
    }

    static runCustomAction(action: string) {
        CommunicationManager.getInstance().sendMessage({
            name: "run-custom-action",
            data: action
        });
    }

    static pageChanged(pageName: string) {
        CommunicationManager.getInstance().sendMessage({
            name: "page-changed",
            data: {
                name: pageName
            }
        });
    }

    static closeWidget() {
        CommunicationManager.getInstance().sendMessage({
            name: "close-widget",
        });
    }

    static playPing() {
        CommunicationManager.getInstance().sendMessage({
            name: "play-ping",
            data: {}
        });
    }

    static notifyEvent(name: string, data?: any) {
        CommunicationManager.getInstance().sendMessage({
            name: "notify-event",
            data: {
                type: name,
                data: data,
            }
        });
    }
}
