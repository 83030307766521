import Link from "@tiptap/extension-link";

const CustomLink = Link.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            linktype: {
                default: 'link',
                renderHTML: (attributes) => {
                    return {
                        'data-linktype': attributes.linktype,
                        class: `linktype-${attributes.linktype}`,
                    };
                },
            },
            bgcolor: {
                default: '#485bff',
                renderHTML: (attributes) => {
                    if (attributes.linktype !== 'button') {
                        return null;
                    }
                    return {
                        'data-bgcolor': attributes.bgcolor,
                        style: `background-color: ${attributes.bgcolor};`,
                    };
                },
            },
        };
    },
});


export default CustomLink;