import moment from 'moment-timezone';
import { useConfigStore } from '../Stores/Config';

export const utcDateFromTime = (time: string, day: string | number) => {
    const config = useConfigStore.getState().config;
    const timeParts = time.split(':');
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    return moment.tz(config.timezone ?? "Universal").set("hour", hours).set("minute", minutes).set("second", 0).set("millisecond", 0).day(day).utc();
}

export const isOnline = () => {
    const config = useConfigStore.getState().config;
    var openingTimes = [];
    var earliestOpeningTime = moment().utc();
    var nextOpeningTime = null;

    if (!config.openingTimes) {
        return {
            online: false,
            nextOpeningTime
        }
    }

    for (let i = 0; i < config.openingTimes.length; i++) {
        const openingTime = config.openingTimes[i];
        var days = [];
        if (openingTime.day === 'Weekdays') {
            days = [1, 2, 3, 4, 5];
        } else if (openingTime.day === 'Weekends') {
            days = [6, 0];
        } else if (openingTime.day === 'All days') {
            days = [0, 1, 2, 3, 4, 5, 6];
        } else {
            days.push(openingTime.day);
        }

        for (let j = 0; j < days.length; j++) {
            const day = days[j];
            const dateOpening = utcDateFromTime(openingTime.openingTime, day);
            const dateClosing = utcDateFromTime(openingTime.closingTime, day);
            openingTimes.push([dateOpening, dateClosing]);

            if (dateOpening.isBefore(earliestOpeningTime)) {
                earliestOpeningTime = dateOpening;
            }
            if (dateOpening.isAfter(moment().utc()) && (nextOpeningTime == null || dateOpening.isBefore(nextOpeningTime))) {
                nextOpeningTime = dateOpening;
            }
        }
    }

    // Check if the next 
    if (!nextOpeningTime) {
        nextOpeningTime = earliestOpeningTime.add(7, 'day');
    }

    var isOnline = false;
    const currentDate = moment().utc();
    for (let i = 0; i < openingTimes.length; i++) {
        const openingTime = openingTimes[i];
        if (currentDate.isBetween(openingTime[0], openingTime[1])) {
            isOnline = true;
        }
    }

    return {
        online: isOnline,
        nextOpeningTime
    };
}