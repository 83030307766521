import WidgetMenu from '../../Components/WidgetMenu/WidgetMenu';
import WidgetPoweredBy from '../../Components/WidgetPoweredBy/WidgetPoweredBy';
import "./Menu.scss";

function Menu() {
    return (
        <div className="main-menu">
            <div className="main-menu--inner">
                <WidgetMenu />
                <WidgetPoweredBy />
            </div>
        </div>
    );
}

export default Menu;
