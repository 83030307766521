export const getDataDescription = ({ data, maxLength = 50 }: { data: any, maxLength: number }) => {
    if (!data) {
        return "No content";
    }

    let descriptionData = '';
    if (data.form) {
        const formFields = Object.keys(data.form).map(e => data.form[e]);

        let description = '';

        const textFormFields: any = formFields.filter(
            (item: any) => item.type === 'textarea' || item.type === 'text',
        );
        for (let i = 0; i < textFormFields.length; i++) {
            description += textFormFields[i].value;
            description += ' • ';
        }

        const otherFields: any = formFields.filter(
            (item: any) => item.type !== 'textarea' && item.type !== 'text',
        );
        for (let i = 0; i < otherFields.length; i++) {
            if (otherFields[i].type === 'rating') {
                description += `${getEmojiForVal(
                    Math.round(otherFields[i].value * 10),
                )} Rating: ${formatCSATScore(otherFields[i].value)}`;
            } else {
                description += otherFields[i].value;
            }

            description += ' • ';
        }

        if (description !== '') {
            descriptionData = description.slice(0, -3);
        } else {
            descriptionData = description;
        }
    } else if (data.formData && data.formData.title) {
        descriptionData = data.formData.title;
    } else if (data.description) {
        descriptionData = data.description;
    } else if (data.formData && data.formData.description) {
        descriptionData = data.formData.description;
    } else if (data.data && data.data.text) {
        descriptionData = data.data.text;
    }
    
    // Remove line breaks
    descriptionData = descriptionData.replace(/(\r\n|\n|\r)/gm, ' ');
    if (descriptionData === '') {
        return 'No content';
    }

    return truncate(descriptionData, maxLength);
};

export const truncate = (str: string, n: number) => {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

export const getEmojiForVal = (val: number) => {
    if (val >= 80) {
        return '🤩';
    }
    if (val >= 60) {
        return '🙂';
    }
    if (val >= 40) {
        return '😐';
    }
    if (val >= 20) {
        return '😢';
    }
    return '😡';
};

export const formatCSATScore = (val: number) => {
    const finalVal = Math.round(val * 10);
    return `${finalVal}%`;
};

export const stripHtml = (html: any) => {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
}
