import create from "zustand";
import Communicator from "../Helper/Communicator";
import { useRouterStore } from "./Router";

interface AppViewState {
  currentApp?: {
    id: string;
    name: string;
    url: string;
  };
  onClose?: () => void;
  pageStack: string[];
  appData: any;
  appFrame?: HTMLIFrameElement;
  extendedView?: boolean;
  showExtendedViewTitle?: boolean;
  openApp: (
    url: string,
    name: string,
    title?: string,
    onClose?: () => void
  ) => void;
  replacePageTitle: (title: string, extendScreen?: boolean) => void;
  pushPage: (title: string, extendScreen?: boolean) => void;
  getCurrentPage: () => string;
  setAppData: (appData: any) => void;
  popPage: () => void;
  setAppFrame: (frame: HTMLIFrameElement) => void;
  setIsExtendedView: (extendedView: boolean) => void;
  setShowExtendedViewTitle: (showExtendedViewTitle: boolean) => void;
  reset(): void;
}

export const useAppViewStore = create<AppViewState>()((set, get) => ({
  currentApp: undefined,
  appFrame: undefined,
  extendedView: false,
  showExtendedViewTitle: false,
  appData: {},
  pageStack: [],
  setAppData: (appData: any) => {
    set({
      appData: appData,
      pageStack: ["Roadmap"],
    });
  },
  reset() {
    set({
      pageStack: [],
      showExtendedViewTitle: false,
      extendedView: false,
    });
  },
  setIsExtendedView(extendedView) {
    if (extendedView) {
      Communicator.pageChanged("appextended");
    } else {
      Communicator.pageChanged("app");
    }

    set({
      extendedView,
    });
  },
  setShowExtendedViewTitle(showExtendedViewTitle) {
    set({
      showExtendedViewTitle: showExtendedViewTitle,
    });
  },
  openApp: (
    url: string,
    name: string,
    title?: string,
    onClose?: () => void
  ) => {
    var newStack = [];
    if (title) {
      newStack.push(title);
    }

    set({
      currentApp: {
        id: url,
        name,
        url: url,
      },
      pageStack: newStack,
      onClose,
    });

    useRouterStore.getState().setPage("appview");
  },
  replacePageTitle: (title: string, extendScreen = false) => {
    get().setIsExtendedView(extendScreen);

    set({
      pageStack: [...get().pageStack.slice(0, -1), title],
    });
  },
  pushPage: (title: string, extendScreen = false) => {
    get().setIsExtendedView(extendScreen);

    if (
      get().pageStack.length > 0 &&
      get().pageStack[get().pageStack.length - 1] === title
    ) {
      return;
    }

    set({
      showExtendedViewTitle: false,
      pageStack: [...get().pageStack, title],
    });
  },
  popPage: () => {
    get().setIsExtendedView(false);
    get().setShowExtendedViewTitle(false);

    var stack = [...get().pageStack];
    stack.pop();
    set({
      pageStack: stack,
    });

    if (stack.length > 0) {
      if (get().appFrame) {
        get().appFrame?.contentWindow?.postMessage(
          JSON.stringify({ name: "goback" }),
          "*"
        );
      }
    }
  },
  getCurrentPage: () => {
    if (get().pageStack.length > 0) {
      return get().pageStack[get().pageStack.length - 1];
    }

    return "";
  },
  setAppFrame: (frame: HTMLIFrameElement) => {
    set({
      appFrame: frame,
    });
  },
}));
