import CommunicationManager from "./CommunicationManager";

export default class HeightUpdateManager {
    private static _instance: HeightUpdateManager;
    private lastHeight: number = -1;

    static getInstance() {
        if (!this._instance) {
            this._instance = new HeightUpdateManager();
        }
        return this._instance;
    }

    constructor() { }

    sendHeight() {
        const feedbackFlowElement = document.querySelector(".feedback-flow") as HTMLElement;
        if (feedbackFlowElement) {
            const height = feedbackFlowElement.offsetHeight;
            if (height && height !== this.lastHeight && height > 0) {
                CommunicationManager.getInstance().sendMessage({
                    name: "height-update",
                    data: height,
                });
            }
            this.lastHeight = height;
        }
    }

    start() {
        if (window.self === window.top) {
            return;
        }

        this.sendHeight();
        window.addEventListener("resize", this.sendHeight.bind(this));
        var observer = new MutationObserver(this.sendHeight.bind(this));
        var config = { attributes: true, childList: true, characterData: true, subtree: true };
        observer.observe(window.document, config);
    }
}
