import { NodeViewWrapper } from "@tiptap/react";

interface HelpCenterArticleAttributes {
  articleId: string;
  articleTitle: string;
  articleDescription: string;
  articleUrl: string;
}

const HelpCenterArticle = ({
  node,
  updateAttributes,
  selected,
  editor,
}: any) => {
  const { articleTitle, articleDescription, articleUrl } =
    node?.attrs as HelpCenterArticleAttributes;

  return (
    <NodeViewWrapper>
      <div
        data-url={articleUrl}
        className={`helpcenter-conversation-article${selected ? "--selected" : ""
          }`}
      >
        <div className="article-header-container">
          <div className="article-header">{articleTitle}</div>
        </div>
        <div className="article-description">{articleDescription}</div>
      </div>
    </NodeViewWrapper>
  );
};

export default HelpCenterArticle;
