import { useConfigStore } from "../../Stores/Config";
import { ReactComponent as GleapBotIcon } from '../../Assets/GleapBotIcon.svg';
import "./ChatAvatar.scss";

function ChatAvatar({
    user,
    session,
}: {
    user?: any;
    session?: any;
}) {
    const operatorAvatarImage = useConfigStore(
        (state) => state.config.operatorAvatarImage
    );

    const renderAvatarContent = () => {
        if (user) {
            if (user.profileImageUrl) {
                return <img src={user.profileImageUrl} />;
            }
        }

        if (operatorAvatarImage && operatorAvatarImage.length > 0) {
            return <img src={operatorAvatarImage} />;
        }

        return <GleapBotIcon />;
    };

    if (session) {
        return null;
    }

    return <div className="avatar">{renderAvatarContent()}</div>;
};

export default ChatAvatar;