import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconButton.scss';

function IconButton({ onClick, icon, className, unreadCount }: { onClick: () => void, icon: any, className?: string; unreadCount?: number }) {
    return (
        <div className={`icon-button ${className}`} onClick={onClick}>
            <FontAwesomeIcon fontSize={18} icon={icon} />
            {unreadCount && unreadCount > 0 ? <div className="icon-button--unread">{unreadCount > 9 ? '9+' : unreadCount}</div> : null}
        </div>
    );
}

export default IconButton;
