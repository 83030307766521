import { faBug, faChevronRight, faPaperclipVertical, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Color from "@tiptap/extension-color";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Youtube from "@tiptap/extension-youtube";
import { generateHTML } from "@tiptap/html";
import StarterKit from "@tiptap/starter-kit";
import moment from "moment";
import { ReactComponent as CaretUpIcon } from "../../Assets/caret-up.svg";
import { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "../../Assets/angle-right-solid.svg";
import { ReactComponent as CheckIcon } from '../../Assets/check-solid.svg';
import Communicator from "../../Helper/Communicator";
import { contentWithVariables } from "../../Helper/ContentReplace";
import CustomLink from "../../Helper/CustomLink";
import { getDataDescription } from "../../Helper/FeedbackItemHelper";
import { HelpCenterArticleExtension } from "../../Helper/HelpcenterExtension";
import IFrame from "../../Helper/IFrame";
import { isOnline } from "../../Helper/OnlineState";
import { replaceAll } from "../../Helper/String";
import { useConfigStore } from "../../Stores/Config";
import { useSessionStore } from "../../Stores/Session";
import { Ticket, TicketChatMessage, useTicketStore } from "../../Stores/Ticket";
import BotActionChatMessage from "../BotActionChatMessage/BotActionChatMessage";
import ChatAvatar from "../ChatAvatar/ChatAvatar";
import { ratingOptions } from "../RatingFormItem/RatingFormItem";
import "./ChatMessage.scss";
import FeatureRequestSuggestions from "../FeatureRequestSuggestions/FeatureRequestSuggestions";
import { useFeatureRequestStore } from "../../Stores/FeatureRequests";
import { useFormStore } from "../../Stores/Form";
import { AppMode, useRouterStore } from "../../Stores/Router";
import { useAppViewStore } from "../../Stores/AppView";

function ChatMessage({
  chatMessage,
  lastOfGroup = false,
  isCreator = false,
  showTime = true,
  typingDelay = 0,
  forceTyping = false,
}: {
  chatMessage: TicketChatMessage;
  lastOfGroup?: boolean;
  isCreator?: boolean;
  showTime?: boolean;
  typingDelay?: number;
  forceTyping?: boolean;
}) {
  const ticketStore = useTicketStore();
  const configStore = useConfigStore();
  const [isTyping, setIsTyping] = useState(typingDelay > 0 ? true : false);

  useEffect(() => {
    var timer: any = null;
    if (typingDelay && typingDelay > 0) {
      timer = setTimeout(() => {
        setIsTyping(false);
      }, typingDelay);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const openingTimeAutoResponderText = () => {
    if (ticketStore.typingUser) {
      return null;
    }

    const { online, nextOpeningTime } = isOnline();

    var message = "";
    if (online) {
      message = `${configStore.translateText("We usually reply")} <b>🕘 ${configStore.translateText(configStore.config.replyTime)}</b>.`;
    } else {
      message = `${configStore.translateText("We are currently offline but will get back to you as soon as possible.")}\n\n${configStore.translateText("We are back")} <b>🕘 ${moment(nextOpeningTime).fromNow()}</b>.`;
    }

    return message;
  }

  const renderContent = () => {
    if (isTyping || forceTyping) {
      return <div className="dot-flashing"></div>;
    }

    if (chatMessage.type === "FEATURE_REQUEST_DUPLICATES") {
      return (<FeatureRequestSuggestions />);
    }

    if (chatMessage?.data?.answerBot && !chatMessage.data?.hasAnswer) {
      return (
        <div
          className="message-text"
        >{configStore.translateText('Unfortunately, I\'m not able to answer your question at the moment.')}</div>);
    }

    if (
      chatMessage.type === "TEXT" ||
      chatMessage.type === "SHARED_COMMENT" ||
      chatMessage.type === "EMAIL_MESSAGE" ||
      chatMessage.type === "BOT"
    ) {
      // Special message for reply times.
      if (chatMessage.data?.showReplyTimes) {
        var openingTime = openingTimeAutoResponderText();
        if (!openingTime) {
          return null;
        }

        return (
          <div
            className="message-text"
            dangerouslySetInnerHTML={{ __html: openingTime }}
          ></div>
        );
      }

      var contentData = chatMessage?.data?.content;
      var content: any = "";
      if (contentData) {
        if (
          contentData &&
          (typeof contentData === "string" || contentData instanceof String)
        ) {
          content = replaceAll(contentData, "\n", "<br>");
        } else {
          if (chatMessage.data.content) {
            try {
              content = generateHTML(chatMessage.data.content, [
                StarterKit.configure({ codeBlock: {}, heading: false }),
                Heading.configure({
                  levels: [1, 2, 3],
                }),
                Mention,
                Placeholder,
                CustomLink,
                Image,
                TextStyle,
                Youtube.configure({ controls: true }),
                IFrame,
                HelpCenterArticleExtension,
                Color.configure({
                  types: ["textStyle"],
                }),
              ]);

              let regex = /<helpcenterarticle.*?>.*?<\/helpcenterarticle>/gs;
              let matches = Array.from(content.matchAll(regex));

              for (let i = 0; i < matches.length; i++) {
                let match: any = matches[i];
                let textToReplace = match[0];

                // perform the necessary replacements on textToReplace
                let replacedText = replaceAll(textToReplace, "&lt;", "<");
                replacedText = replaceAll(replacedText, "&gt;", ">");
                replacedText = replaceAll(replacedText, "&quot;", '"');
                replacedText = replaceAll(
                  replacedText,
                  "helpcenterarticle",
                  "div"
                );
                // replace the original text with the modified text
                content = content.replace(textToReplace, replacedText);
              }

              content = replaceAll(content, "\n", "<br>");
            } catch (_) { }
          }
        }
      } else if (
        chatMessage?.data?.text &&
        (typeof chatMessage.data.text === "string" ||
          chatMessage.data.text instanceof String)
      ) {
        content = replaceAll(chatMessage.data.text, "\n", "<br>");
      }

      content = contentWithVariables(content, {
        name: useSessionStore.getState().getName(),
      });

      return (
        <div
          className="message-text"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      );
    }
    if (chatMessage.type === "BOT_REPLY") {
      return (
        <div
          className="message-text"
        >{chatMessage?.data?.content}</div>
      );
    }
    if (chatMessage.type === "FEEDBACK_ITEM") {
      const feedbackItem = chatMessage.data as Ticket;

      if (feedbackItem && feedbackItem.form) {
        const formKeys = Object.keys(feedbackItem.form);
        return (
          <div>
            {formKeys.map((formKey) => {
              const formItem = feedbackItem.form[formKey];

              return (
                <div key={formKey} className="message-form-item">
                  <div className="message-form-item-title">
                    {formItem.title}
                  </div>
                  <div className="message-form-item-value">
                    {formItem.value}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }

      const content = getDataDescription({
        data: feedbackItem,
        maxLength: 10000,
      });
      if (content === "No content") {
        return null;
      }

      return <div className="message-text">{content}</div>;
    }

    return null;
  };

  const renderAdditionalContent = () => {
    if (chatMessage.type === "FEEDBACK_ITEM") {
      const feedbackItem = chatMessage.data as Ticket;

      if (feedbackItem && feedbackItem.screenshotUrl) {
        return (
          <div className="chat-message-image">
            <img src={feedbackItem.screenshotUrl} />
          </div>
        );
      }
    }

    return null;
  };

  const isFileImage = (file: any) => {
    return file && file["type"].split("/")[0] === "image";
  };

  if (chatMessage.type === "BOT_INPUT_REPLY") {
    return (
      <BotActionChatMessage date={chatMessage.createdAt} hideAvatar={!lastOfGroup}>
        <div
          className="bot-input-reply"
        >
          {chatMessage?.data?.type === "rateconversation" ? <>
            <div className="bot-input-reply-label">{chatMessage?.data?.question}</div>
            <div className="bot-input-reply-rating-response">
              {[1, 2.5, 5, 7.5, 10].map((value: any, index: number) => {
                return (<div key={index} className={`bot-input-reply-rating-response-option ${chatMessage?.data?.rating === value && 'bot-input-reply-rating-response-option--selected'}`}>
                  {ratingOptions[index]}
                </div>);
              })}
            </div>
          </> : <>
            <div className="bot-input-reply-label">{chatMessage?.data?.question}</div>
            <div className="bot-input-reply-response">{chatMessage?.data?.content}</div>
            <CheckIcon className="bot-input-reply-check" />
          </>}
        </div>
      </BotActionChatMessage>
    )
  }

  const content = renderContent();
  if (!content) {
    return null;
  }

  var showSourceTitle = true;
  if (chatMessage.data?.sources) {
    for (var i = 0; i < chatMessage.data.sources.length; i++) {
      const source = chatMessage.data.sources[i];
      if (source.type === "featurerequest" || source.type === "reportabug") {
        showSourceTitle = false;
        break;
      }
    }
  }

  return (
    <div
      className={`chat-message ${chatMessage.type === "FEATURE_REQUEST_DUPLICATES" && "chat-message--featurerequest-duplicates"} ${forceTyping && "chat-message--typing"} ${chatMessage.data?.answerBot && "chat-message--aibot"} ${isCreator && "chat-message--creator"} ${lastOfGroup && "chat-message--last-of-group"
        }`}
    >
      <ChatAvatar user={chatMessage.user} session={chatMessage.session} />
      <div className="message-container">
        <div className="message">
          {chatMessage?.data?.answerBot && <>
            <div className="answer-bot">
              <div className="answer-bot-tag">AI</div> {configStore.translateText("Answer")}
            </div>
          </>}
          {content}
          {chatMessage.data?.sources && chatMessage.data?.sources.length > 0 && <>
            <div className="link-sources">
              {showSourceTitle &&
                <div className="link-sources-header">
                  {configStore.translateText("Sources")}</div>}
              <div className="link-sources-links">
                {chatMessage.data?.sources.map((source: any) => {
                  const onClick = () => {
                    if (source.type === 'featurerequest') {
                      useFeatureRequestStore.getState().openFeatureRequest(source.shareToken);
                    } else if (source.type === 'createfeaturerequest') {
                      const flow = "featurerequests";
                      useFormStore.setState({
                        feedbackFlow: flow,
                        isSurveyFlow: false,
                        hideBackButton: false,
                      });
                      useRouterStore.getState().setPage(
                        "flow",
                        {
                          flowId: flow,
                        },
                        AppMode.WIDGET
                      );
                      useAppViewStore.getState().reset();
                    } else if (source.type === 'reportabug') {
                      const flow = "bugreporting";
                      useFormStore.setState({
                        feedbackFlow: flow,
                        isSurveyFlow: false,
                        hideBackButton: false,
                        preFillData: {
                          bugdescription: source.bugreport ?? "",
                        }
                      });
                      useRouterStore.getState().setPage(
                        "flow",
                        {
                          flowId: flow,
                        },
                        AppMode.WIDGET
                      );
                      useAppViewStore.getState().reset();
                    } else {
                      Communicator.openURL(source.url, true);
                    }
                  };

                  if (source.type === 'createfeaturerequest') {
                    return (<div className="link-source" onClick={onClick}>
                      <div className='link-source--submiticon'>
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                      <div className="link-source--title">{configStore.translateText(source.title)}</div>
                      <FontAwesomeIcon className="link-source--arrow" icon={faChevronRight} />
                    </div>);
                  }

                  if (source.type === 'reportabug') {
                    return (<div className="link-source" onClick={onClick}>
                      <div className='link-source--submiticon'>
                        <FontAwesomeIcon icon={faBug} />
                      </div>
                      <div className="link-source--title">{configStore.translateText(source.title)}</div>
                      <FontAwesomeIcon className="link-source--arrow" icon={faChevronRight} />
                    </div>);
                  }

                  return (<div className="link-source" onClick={onClick}>
                    {source.type === 'featurerequest' && (
                      <div className='link-source--upvotes'>
                        <CaretUpIcon />
                        {source.upvotes > 99 ? "99+" : source.upvotes}
                      </div>
                    )}
                    {source.type === 'createfeaturerequest' && (
                      <div className='link-source--submiticon'>
                        <FontAwesomeIcon icon={faPlus} />
                      </div>)}
                    <div className="link-source--title">{source.title}</div>
                    <FontAwesomeIcon className="link-source--arrow" icon={faChevronRight} />
                  </div>);
                })}
              </div>
            </div>
          </>}
          {chatMessage.attachments &&
            chatMessage.attachments.map((attachment, index) => {
              if (isFileImage(attachment)) {
                return (
                  <img
                    className="attachment-image"
                    src={attachment.url}
                    alt={attachment.name}
                  />
                );
              }
              return (
                <div
                  className="attachment"
                  key={index}
                  onClick={() => {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", attachment.url);
                    xhr.responseType = "blob";
                    xhr.onload = function () {
                      if (!xhr.response) {
                        return;
                      }

                      var blob = xhr.response;
                      const url = window.URL.createObjectURL(blob);

                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", attachment.name);
                      document.body.appendChild(link);

                      link.click();
                      link.remove();
                    };
                    xhr.send();
                  }}
                >
                  <a className="attachment-link">
                    <FontAwesomeIcon fontSize={16} icon={faPaperclipVertical} />
                    {attachment.name}
                  </a>
                </div>
              );
            })}
        </div>
        {renderAdditionalContent()}
        {showTime && (
          <div className="message-date">
            {chatMessage.type === "BOT" && "Bot · "}
            {chatMessage.sending
              ? `Sending...`
              : moment(chatMessage.createdAt ?? new Date()).fromNow()}
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatMessage;
