import React from 'react';
import { useConfigStore } from '../../Stores/Config';
import { ReactComponent as GleapLogo } from '../../Assets/PoweredByGleapLogo.svg';
import { ReactComponent as Bolt } from '../../Assets/bolt-solid.svg';
import "./WidgetPoweredBy.scss";
import Communicator from '../../Helper/Communicator';

function WidgetPoweredBy() {
    const config = useConfigStore(state => state.config);
    const showGleapLogo = typeof config.hideBranding === "undefined" || !config.hideBranding;

    return (
        <div className={`powered-by ${!showGleapLogo && "powered-by--hidden"}`} onClick={() => {
            if (showGleapLogo) {
                Communicator.openURL("https://www.gleap.io");
            }
        }}>
            {showGleapLogo && (<div className='powered-by--inner' dir="ltr">
                <Bolt className='bolt' />
                <span>Powered by</span>
                <GleapLogo className='logo' />
            </div>)}
        </div>
    );
}

export default WidgetPoweredBy;
