import { useActionStore } from "../Stores/Actions";
import { useConfigStore } from "../Stores/Config";
import { useSessionStore } from "../Stores/Session";
import { useFormStore } from "../Stores/Form";
import { AppMode, useRouterStore } from "../Stores/Router";
import { useWebSocketStore } from "../Stores/WebSocket";
import { useTicketStore } from "../Stores/Ticket";
import { useNewsStore } from "../Stores/News";
import { useFeatureRequestStore } from "../Stores/FeatureRequests";
import { useAppViewStore } from "../Stores/AppView";
import { useHelpCenterStore } from "../Stores/HelpCenter";
import Communicator from "./Communicator";
import { resetWidget } from "../Components/WidgetHeader/WidgetHeader";

const FORM_SENT_TIMEOUT = 3000;

const createRecaptcha = function () {
  let script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("defer", "");
  script.id = "recaptchaScript";
  script.src =
    "https://www.recaptcha.net/recaptcha/api.js?render=6LeMpiwcAAAAAAuGag4PWJvwSSgH0mCVX7EDQIjT";
  document.getElementsByTagName("head")[0].appendChild(script);
};

export default class CommunicationManager {
  private static _instance: CommunicationManager;
  private static injectedScript: boolean = false;
  private static isWidgetOpen: boolean = false;

  static getInstance() {
    if (!this._instance) {
      this._instance = new CommunicationManager();
    }
    return this._instance;
  }

  constructor() {
    this.startMessageListener();
    this.visibilityChangeListener();
  }

  visibilityChangeListener() {
    document.addEventListener("visibilitychange", () => {
      if (CommunicationManager.isWidgetOpen) {
        if (document.hidden) {
          this.disconnect();
        } else {
          this.reconnect();
          useTicketStore.getState().silentRefreshTickets();

          if (useTicketStore.getState().currentTicket) {
            useTicketStore.getState().loadComments(true);
            useTicketStore.getState().loadCurrentActionFlow();
          }
        }
      }
    });
  }

  sendMessage(data: any) {
    var parentWindow = (window as any).top;
    if ((window as any).parent != (window as any).top) {
      parentWindow = (window as any).parent;
    }

    if (parentWindow) {
      parentWindow.postMessage(JSON.stringify(data), "*");
    }
  }

  reconnect() {
    useWebSocketStore.getState().connect();
  }

  disconnect() {
    useWebSocketStore.getState().disconnect();
  }

  startMessageListener() {
    // Add window message listener.
    window.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === "app") {
          if (data.name === "page") {
            useAppViewStore
              .getState()
              .pushPage(data.data.title, data.data.extendScreen);
          }
          if (data.name === "pagetitle") {
            useAppViewStore
              .getState()
              .replacePageTitle(data.data.title, data.data.extendScreen);
          }
          if (data.name === "data") {
            useAppViewStore.getState().setAppData(data.data);
          }
          if (data.name === "open-url") {
            Communicator.openURL(data.data);
          }
          if (data.name === "show-app-scroll-title") {
            useAppViewStore
              .getState()
              .setShowExtendedViewTitle(data.data?.show ? true : false);
          }
        }

        if (
          data.data &&
          data.data.hideBackButton &&
          data.name !== "start-feedbackflow" &&
          data.name !== "start-survey"
        ) {
          useRouterStore.getState().setHideBackButton(data.data.hideBackButton);
        }

        if (data.name === "config-update") {
          if (data.data.config) {
            useConfigStore.getState().setConfig(data.data.config);

            if (
              data.data.config &&
              data.data.config.spamProtection &&
              !CommunicationManager.injectedScript
            ) {
              CommunicationManager.injectedScript = true;
              createRecaptcha();
            }
          }
          if (data.data.isApp) {
            useConfigStore.setState({
              isApp: true,
            });
          }
          if (data.data.overrideLanguage) {
            useConfigStore
              .getState()
              .setOverrideLanguage(data.data.overrideLanguage);
          }
          if (data.data.actions) {
            useActionStore.setState({
              actions: data.data.actions,
            });
          }
        }
        if (data.name === "session-update") {
          useSessionStore.setState({
            apiUrl: data.data.apiUrl,
            sdkKey: data.data.sdkKey,
          });

          useSessionStore.getState().setSession(data.data.sessionData);
        }
        if (data.name === "widget-status-update") {
          CommunicationManager.isWidgetOpen = data.data.isWidgetOpen
            ? true
            : false;
          if (data.data.isWidgetOpen) {
            this.reconnect();
            useTicketStore.getState().loadConversations();
            useNewsStore.getState().loadLatestNews();
            useConfigStore.getState().loadTeamInfo();
          } else {
            this.disconnect();

            useRouterStore.setState({
              firstAnimation: true,
            });

            // Reset when widget is closed in news or app view extended views.
            if (
              useAppViewStore.getState().extendedView ||
              useRouterStore.getState().currentPage === "newsdetails"
            ) {
              resetWidget();
            }

            // Check for active survey.
            if (useRouterStore.getState().appMode !== "widget") {
              useRouterStore.getState().setPage("menu");
              useFormStore.getState().resetForm();
            }
          }
        }
        if (data.name === "start-feedbackflow") {
          useFormStore.setState({
            feedbackFlow: data.data.flow,
            isSurveyFlow: false,
            hideBackButton: data.data.hideBackButton,
          });
          useRouterStore.getState().setPage(
            "flow",
            {
              flowId: data.data.flow,
            },
            AppMode.WIDGET
          );
          useAppViewStore.getState().reset();
        }
        if (data.name === "start-survey") {
          useFormStore.setState({
            feedbackFlow: data.data.flow,
            isSurveyFlow: true,
            hideBackButton: data.data.hideBackButton,
          });
          useRouterStore.getState().setPage(
            "flow",
            {
              flowId: data.data.flow,
            },
            data.data.format
          );
          useAppViewStore.getState().reset();
        }
        if (data.name === "start-bot") {
          useTicketStore.getState().createNewConversation({
            botId: data.data.botId,
          });
        }
        if (data.name === "open-home") {
          useRouterStore.getState().setPage("menu");
        }
        if (data.name === "open-conversations") {
          useRouterStore.getState().setPage("conversations");

          // Reload.
          useTicketStore.getState().loadConversations();
        }
        if (data.name === "open-conversation") {
          useRouterStore.getState().setPage("conversation", {
            shareToken: data.data.shareToken,
          });

          // Reload.
          const currentTicket = useTicketStore.getState().currentTicket;
          if (currentTicket) {
            useTicketStore.getState().loadComments(true);
          }
        }
        if (data.name === "open-news-article") {
          useRouterStore.getState().setPage("newsdetails", {
            id: data.data.id,
          });
        }
        if (data.name === "open-news") {
          useRouterStore.getState().setPage("news");
        }
        if (data.name === "open-helpcenter") {
          useHelpCenterStore.getState().open();
        }
        if (data.name === "open-helpcenter-search") {
          useHelpCenterStore.getState().searchHelpCenter(data.data.term);
        }
        if (data.name === "open-help-collection") {
          useHelpCenterStore.getState().openCollection(data.data.collectionId);
        }
        if (data.name === "open-help-article") {
          useHelpCenterStore.getState().openArticle(data.data.articleId);
        }
        if (data.name === "open-feature-requests") {
          useFeatureRequestStore.getState().open();
        }
        if (data.name === "session-cleared") {
          try {
            localStorage.clear();
          } catch (exp) { }
        }
        if (data.name === "prefill-form-data") {
          useFormStore.getState().setPreFillFormData(data.data);
        }
        if (data.name === "feedback-sent") {
          useFormStore.getState().setFormSent();

          let formSentTimeout =
            useConfigStore.getState().config.thankYouDuration ||
            FORM_SENT_TIMEOUT;

          const formAction = useFormStore.getState().action;
          setTimeout(() => {
            if (
              formAction &&
              formAction.showConversation &&
              data &&
              data.data &&
              data.data.shareToken
            ) {
              useTicketStore.getState().loadConversations();
              useRouterStore.getState().setPage("conversation", {
                shareToken: data.data.shareToken,
              });
            } else {
              this.sendMessage({
                name: "close-widget",
              });
              useRouterStore.getState().setPage("menu");
            }
            useFormStore.getState().clearFeedbackFlowOptions();
          }, formSentTimeout);
        }
        if (data.name === "feedback-sending-failed") {
          useFormStore.setState({
            formSent: false,
            sendingForm: false,
            formError: data.data,
          });
        }
        if (data.name === "set-form-data") {
          useFormStore
            .getState()
            .setFormData(data.data.formKey, data.data.data);
        }
      } catch (exp) { }
    });
  }
}
