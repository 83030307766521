import { useCallback, useEffect } from 'react';
import { ReactComponent as CheckIcon } from '../../Assets/check-solid.svg';
import { useFormStore } from '../../Stores/Form';
import "./MultipleChoiceFormItem.scss";

function MultipleChoiceFormItem({ formItem }: { formItem: any }) {
    const formData = useFormStore(state => state.formData);
    const fieldFormData = formData[formItem.name];
    const setFormData = useFormStore(state => state.setFormData);

    const getIndex = (character: string) => {
        return character.charCodeAt(0) - 97;
    }

    const getCharacter = (index: number) => {
        return String.fromCharCode(97 + index);
    }

    const handleUserKeyPress = useCallback((event: any) => {
        const { key, target } = event;
        if (key && target.tagName !== "INPUT" && target.tagName !== "TEXTAREA") {
            const index = getIndex(key);
            if (index >= 0 && index < formItem.choices.length) {
                setFormData(formItem.name, {
                    value: formItem.choices[index],
                    dirty: true
                }, true);
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const renderOption = (option: any, index: number) => {
        return (<div key={index} className={`multiple-choice-form-item-choice ${fieldFormData && fieldFormData.value === option && "multiple-choice-form-item-choice--selected"}`} onClick={() => {
            setFormData(formItem.name, {
                value: option,
                dirty: true
            }, true);
        }}>
            <div className='multiple-choice-form-item-choice-shortcut'>{getCharacter(index)}</div>
            <div className='multiple-choice-form-item-choice-value'>{option}</div>
            <div className='multiple-choice-form-item-choice-check'><CheckIcon /></div>
        </div>);
    };

    return (
        <div className="multiple-choice-form-item">
            {formItem && formItem.choices && formItem.choices.map((choice: any, index: number) => (
                renderOption(choice, index)
            ))}
        </div>
    );
}

export default MultipleChoiceFormItem;
